import { type Options } from 'react-markdown';

import logger from '../../logger/logger';

export const log = logger.scoped('collective');

export const markdownComponents: Options['components'] = {
  h1: ({ children }) => (
    <h1 className='text-2xl font-bold mt-5 mb-2.5'>{children}</h1>
  ),
  h2: ({ children }) => (
    <h2 className='text-xl font-bold mt-5 mb-2.5'>{children}</h2>
  ),
  h3: ({ children }) => (
    <h3 className='text-lg font-bold mt-5 mb-2.5'>{children}</h3>
  ),
  h4: ({ children }) => (
    <h4 className='text-base font-bold my-2.5'>{children}</h4>
  ),
  h5: ({ children }) => (
    <h5 className='text-sm font-bold my-2.5'>{children}</h5>
  ),
  h6: ({ children }) => (
    <h6 className='text-sms font-bold my-2.5'>{children}</h6>
  ),
  ul: ({ children }) => <ul className='list-disc list-inside'>{children}</ul>,
  ol: ({ children }) => (
    <ol className='list-decimal list-inside'>{children}</ol>
  ),
  pre: ({ children }) => (
    <pre className='whitespace-pre overflow-x-auto my-1 bg-light-gray p-2 rounded-md'>
      {children}
    </pre>
  ),
  blockquote: ({ children }) => (
    <blockquote className='border-l-2 border-gray-400 pl-2.5 my-2.5 text-icon-gray'>
      {children}
    </blockquote>
  ),
};
